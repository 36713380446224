<script>
	import Base from '@backend/Base.vue';
	import draggable from 'vuedraggable'
	import BOGen from '@helper/BOGen';
	export default {
		extends: Base,
		components: {
			draggable,
		},
		data() {
			return {
				Name: "SnkAbout",
				input: {
					ctasubmit: ''
				},
				hero: {},
				description: {},
				visimisi: {},
				whatwedo: {
					activities: [{}]
				},
				activity: {},
				isAddActivity: false,
				isEditActivity: false,
			}
		},
		computed: {

		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		methods: {
			submitForm(e, callback) {
				if (e && e.btnLoading()) return;
				let row = {}
				row.hero = Gen.clone(this.hero)
				row.description = Gen.clone(this.description)
				row.visimisi = Gen.clone(this.visimisi)
				row.whatwedo = Gen.clone(this.whatwedo)
				row.type = "update"
				row.submit = this.input.ctasubmit
				BOGen.apirest("/" + this.Name, row, (err, resp) => {
					if (e) e.btnUnloading()
					if (err) return Gen.info(err.resp.message, "danger")
					if (resp.error) {
						if (this.errorFormCallback) return this.errorFormCallback(resp);
						if (callback) return callback(resp);
						return Gen.info(resp.message, "danger")
					}
					if (resp.success) {
						if (this.successFormCallback) return this.successFormCallback(resp);
						Gen.info(resp.message, "success")
						this.refreshData()
					}
				}, "POST")
			},
			openAvtivity() {
				if (this.isAddActivity) this.$set(this, 'isAddActivity', false)
				else this.$set(this, 'isAddActivity', true)
				this.$set(this, 'activity', {
					image: '',
					title: '',
					is_active: 'Y'
				})
			},
			editAvtivity(v, k) {
				k = k==0?true:k
				this.$set(this, 'activity', {})
				if (this.isEditActivity) this.$set(this, 'isEditActivity', false)
				else this.$set(this, 'isEditActivity', true)
				this.$set(this, 'activity', v)
			},
			submitActivity() {
				let val = Gen.clone(this.activity)
				if (this.isEditActivity) this.whatwedo.activities[k] = val
				else this.whatwedo.activities.push(val)
				this.$set(this, 'isAddActivity', false)
				this.$set(this, 'isEditActivity', false)
			},
			addFac() {
				this.fac = {
					mpf_is_active: 'Y',
					type: 'addFac'
				}
			},
			deleteFac(id) {
				var v = id
				swal({
					title: "Are you sure to delete " + this.ObjectName + "?",
					text: "",
					icon: "warning",
					buttons: ["No, Cancel!", "Yes, Sure!"],
					dangerMode: true,
				}).then((ok) => {
					if (ok) {
						BOGen.apirest("/" + this.Name, {
							type: "deleteFac",
							id: v
						}, () => {
							swal("Deleted!", "Information has been deleted.", "success");
							this.refreshData()
						}, "POST")
					}
				});
			},
		},
		watch: {}

	};
</script>
<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<div class="row mt-2">
			<!-- Start Contact -->
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Hero</h5>
						<VForm @resp="submitForm" method="post">
							<div class="row">
								<div class="col-md-12 info"></div>
								<div class="col-md-6">
									<BoField name="sc_background" mandatory label="Hero Image">
										<Uploader :param="{thumbnail:true}" name="sc_background" v-model="hero.sc_background"
											type="background" uploadType="cropping"></Uploader>
									</BoField>
								</div>
								<div class="col-md-6">
									<BoField name="sc_title" v-model="hero.sc_title" mandatory :attr="{minlength:3,maxlength:100,required:true}" :help="'Maximium 100 charcters.'"></BoField>
									<BoField name="sc_sub_title" mandatory :help="'Maximium 150 charcters.'">
										<textarea class="form-control" required="" rows="5" maxlength="150" minlength="5" v-model="hero.sc_sub_title"></textarea>
									</BoField>
								</div>
								<div class="col-md-12 text-right">
									<button type="submit" @click="$set(input,'ctasubmit','hero')"
										class="btn btn-rounded btn-info btn-loading">Update Hero</button>
								</div>
							</div>
						</VForm>
					</div>
				</div>
			</div>
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Description</h5>
						<VForm @resp="submitForm" method="post">
							<div class="row">
								<div class="col-md-6">
									<BoField name="sc_background2" mandatory label="Description Image">
										<Uploader :param="{thumbnail:true}" name="sc_background2" v-model="description.image"
											type="background_desc" uploadType="cropping">
										</Uploader>
									</BoField>
								</div>
								<div class="col-md-6">
									<BoField name="sc_title" v-model="description.title" mandatory :attr="{minlength:3,maxlength:100,required:true}" :help="'Maximium 100 charcters.'"></BoField>
									<BoField name="sc_desc" mandatory>
										<CKEditor name="sc_desc" isRequired class="form-control" v-model="description.description">
										</CKEditor>
									</BoField>
								</div>
								<div class="col-md-12 text-right">
									<button type="submit" @click="$set(input,'ctasubmit','description')" class="btn btn-rounded btn-info btn-loading">Update Description</button>
								</div>
							</div>
						</VForm>
					</div>
				</div>
			</div>
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Visi Misi</h5>
						<VForm @resp="submitForm" method="post">
							<div class="row">
								<div class="col-md-6">
									<BoField name="visi" mandatory label="Visi">
										<CKEditor name="visi" isRequired class="form-control" v-model="visimisi.visi">
										</CKEditor>
									</BoField>
								</div>
								<div class="col-md-6">
									<BoField name="misi" mandatory label="Misi">
										<CKEditor name="misi" isRequired class="form-control" v-model="visimisi.misi">
										</CKEditor>
									</BoField>
								</div>
								<div class="col-md-12 text-right">
									<button type="submit" @click="$set(input,'ctasubmit','visimisi')"
										class="btn btn-rounded btn-info btn-loading">Update Visi Misi</button>
								</div>
							</div>
						</VForm>
					</div>
				</div>
			</div>
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">What We Do</h5>
						<VForm @resp="submitForm" method="post">
							<div class="row">
								<div class="col-md-6">
									<BoField name="title" label="Title" v-model="whatwedo.title" mandatory></BoField>
									<BoField name="sc_sub_title" label="Sub Title" mandatory>
										<textarea class="form-control" v-model="whatwedo.description"></textarea>
									</BoField>
								</div>
								<div class="col-md-6"> </div>
								<div class="col-md-12 mt-3">
									<h5 class="card-title">Business Activities</h5>
									<div v-model="whatwedo.activities" tag="div" class="row">
										<div class="col-md-2" v-for="(v,k) in whatwedo.activities" :key="k">
											<div class="wrap_slider_img">
												<img :src="uploader(v.image)" class="img-responsive" />
												<div class="slider_name">
													<p>{{v.title}}</p>
												</div>
												<a data-toggle="collapse" @click="editAvtivity(v,k)" href="javascript:;" class="bullet_edit"><i
														class="fas fa-pencil-alt"></i></a>
												<a href="javascript:;" @click="whatwedo.activities.splice(k,1)" class="bullet_delete"><i
														class="far fa-trash-alt"></i></a>
												<span class="label label-success" v-if="v.is_active == 'Y'">Active</span>
												<span class="label label-danger" v-else>Inactive</span>
											</div>
										</div>

										<div class="col-md-2">
											<a class="wrap_upload" data-toggle="collapse" @click="openAvtivity()" href="#collapseFac"
												role="button" aria-expanded="false" aria-controls="collapseFac">
												<div class="ic_wrap">
													<i class="fas fa-plus"></i>
													<p>New Activities</p>
												</div>
											</a>
										</div>
									</div>

									<VForm @resp="submitActivity" method="post" v-show="isAddActivity||isEditActivity!=false">
										<div class="row mt-4">
											<div class="col-12 info-fac"></div>
											<div class="col-md-4 mb-3">
												<BoField name="mpf_img" mandatory>
													<Uploader :param="{thumbnail:true}" name="mpf_img" v-model="activity.image" type="facilities"
														uploadType="Upload"></Uploader>
												</BoField>
											</div>
											<div class="col-md-8 mb-3">
												<div class="row">
													<div class="col-md-12">
														<div class="panel_group">
															<div class="panel_heading">Content</div>
															<div class="panel_body">
																<div class="row">
																	<div class="col-md-9">
																		<BoField name="mpf_title" v-model="activity.title" mandatory>
																		</BoField>
																	</div>
																	<div class="col-md-9">
																		<BoField name="mpf_is_active">
																			<div class="row">
																				<radio name="mpf_is_active" v-model="activity.is_active" option="Y"
																					:attr="validation('mpf_is_active')">
																					Active</radio>
																				<radio name="mpf_is_active" v-model="activity.is_active" option="N">
																					Inactive</radio>
																			</div>
																		</BoField>
																	</div>
																	<div class="col-md-12">
																		<button type="submit" class="btn btn-rounded btn-success btn-loading">Submit Activities</button>
																	</div>
																</div>
															</div>
														</div>
													</div>
													
												</div>
											</div>
										</div>
									</VForm>
								</div>
								<div class="col-md-12 text-right">
									<button type="submit" @click="$set(input,'ctasubmit','whatwedo')"
										class="btn btn-rounded btn-info btn-loading">Update What We Do</button>
								</div>
							</div>
						</VForm>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>